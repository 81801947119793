import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Real1 from '../../../images/work/04_PG_MATTHAMS/PHOTOS/MA_01.jpg'
import Real2 from '../../../images/work/04_PG_MATTHAMS/PHOTOS/MA_02.jpg'
import Real3 from '../../../images/work/04_PG_MATTHAMS/PHOTOS/MA_03.jpg'
import Real4 from '../../../images/work/04_PG_MATTHAMS/PHOTOS/MA_04.jpg'
import Real5 from '../../../images/work/04_PG_MATTHAMS/PHOTOS/MA_05.jpg'
import Real6 from '../../../images/work/04_PG_MATTHAMS/PHOTOS/MA_06.jpg'
import Real7 from '../../../images/work/04_PG_MATTHAMS/PHOTOS/MA_07.jpg'
import Real8 from '../../../images/work/04_PG_MATTHAMS/PHOTOS/MA_08.jpg'
import Real9 from '../../../images/work/04_PG_MATTHAMS/PHOTOS/MA_09.jpg'
import Real1s from '../../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_01.jpg'
import Real2s from '../../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_02.jpg'
import Real3s from '../../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_03.jpg'
import Real4s from '../../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_04.jpg'
import Real5s from '../../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_05.jpg'
import Real6s from '../../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_06.jpg'
import Real7s from '../../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_07.jpg'
import Real8s from '../../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_08.jpg'
import Real9s from '../../../images/work/04_PG_MATTHAMS/PHOTOS/450x280/MA-SM_09.jpg'
import Process1 from '../../../images/work/04_PG_MATTHAMS/PROCESS/MAT_01.jpg'
import Process1s from '../../../images/work/04_PG_MATTHAMS/PROCESS/450x280/MAT-SM_01.jpg'

const Residence04 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="THE MATTHAMS RESIDENCE"
        residenceLocation="PACIFIC GROVE, CALIFORNIA"
        infoList={['Remodel of existing 3,500 SF residence with a new exterior stair access to a 590 SF Caretaker unit located above garage.', 'Design - 2016', 'Construction - 2016', 'Contractor: Eric\'s Building Services', 'Structural Engineer: Central Coast Engineers, Inc.']}
        testimonialList={[]}
        photos={[Real1,Real2,Real3,Real4,Real5,Real6,Real7,Real8,Real9]}
        photosSmall={[Real1s,Real2s,Real3s,Real4s,Real5s,Real6s,Real7s,Real8s,Real9s]}
        processPhotos={[Process1]}
        processPhotosSmall={[Process1s]}
        />
    </Container>
  </Layout>
)

export default Residence04;
